.layout {
    display: flex;
    justify-content: center;
    height: 100%;
    flex-wrap: wrap;
}

.box {
    display: flex;
    align-items: center;
    justify-content: stretch;
    width: 33%; 
    flex-direction: column;
    padding: 10px;
}

.swap {
    position: relative;
    width: 100%; 
    padding-bottom: 100%; 
    height: 0;
}

.swap img {
    position: absolute;
    top: 0;
    left: 0;
    width: 90%; 
    height: 90%;
    opacity: 0;
    animation: swap 2s steps(2) infinite;
}

.text {

    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    gap: 5px;
    text-align: center;
}

@keyframes swap {
    0%, 50% { opacity: 1; } 
    50.01%, 100% { opacity: 0; } 
}

#f1 {
    animation-delay: 0s; 
}

#f2 {
    animation-delay: 1s;
}

@media screen and (max-width: 768px) {
    .text p {
        display: none;
    }

    .box {
        width: 100%;
        height: auto;
    }

}