.description {
    margin: 10px;
}
.project-homepage {
    /* background-color: #faecdc; */
    margin: 10px;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
}
.projects-display {

    display: flex;
    flex-wrap: wrap;
    gap: 10vw;
}

@media screen and (max-width: 768px) {
    .projects-display {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10vh;
    }
}

.project-text {
    text-align: left;
}

.list {
    margin: 0px 20px;
}



