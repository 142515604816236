
.homepage-icons-wrapper {
	align-items: center;
	justify-content: space-evenly;
	flex-wrap: wrap; 
	flex-grow: 1;
}
button {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

@media screen and (max-width: 768px) {
	.homepage-icons-wrapper {
		margin: 10px 0px;
		flex-direction: column;
		gap: 30px;
	}
}