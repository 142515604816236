.img-wrapper {
    display: flex;
    gap: 10px;
}

.img-wrapper > div, 
.img-wrapper iframe {
    flex: 1; 
    max-width: 50%;
}

.zoom-slider {
    margin-bottom: 10px;
}

.zoom-slider label {
    margin-right: 10px;
}

.horizontal-divider {
    height: 10px;
}

.block {
    margin: 10px;
}

a {
    display: flex;
    width: 100%;
    height: auto;
}

img {
    width: 100%;
    height: auto;

}

.project-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 5%;
    gap: 10px;
}

h1 {
    color: orange;
    padding: 10px;
    font-size: 5vh;


}